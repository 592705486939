/* global rp$, window */
'use strict';

const get = require('lodash/get');
const dust = require('public/js/lib/dust'); 

const googleMapsUtils = require('public/js/helpers/google-maps');
const utils = require('public/js/helpers/utils');

const DealerInfotTempl = require('public/templates/modules/dealer_selection_modal/dealer_info_template.dust');

import './dealer_selection_modal.scss';

var THE_SCRIPT = function () {
	const $onReady = require('public/js/lib/on-ready');
	const { validatePostalCode } = require('public/js/lib/postal-code-helper');

	// let dealers = [];

	/********************************************
	 * Load Javascript for Dealer Selection Modal Module
	 * -----------------------------------------
	 * @section dealer_selection_modal_js
	 ********************************************/
	(function DealerSelectionModalModule() {
		let $enterZipButton,
			$enterZipMapButton,
			$dealerSelectionModalTrigger,
			$dealerSelectionModal,
			$dealerSelectionError,
			$formErrors,
			$selectedDealerContainer,
			$dealerSelectionContainer,
			$dealersListContainer,
			$zipInput,
			$modalContainer,
			$modalBody,
			$changeDealerWarning,
			$dealerConfirmSection,
			$dealerSelectionSection,
			$dealerConfirmationFooter,
			$dealerConfirmCancel,
			$dealerConfirmSubmit,
			$cartContentList,
			$cartLoadingContainer,
			$productPurchaseModule;

		let map;
		let markers = [];
		const infoWindows = [];
		const dealers_with_label = []; 

		function clearError() {
			$formErrors.html('');
		}

		// -- Zip Submission Container --
		function zipSubmissionWorking() {
			$enterZipButton.addClass('disabled');
			$enterZipMapButton.addClass('disabled');
			$dealerSelectionContainer.addClass('show loading');
		}

		function zipSubmissionReady() {
			$enterZipButton.removeClass('disabled');
			$enterZipMapButton.removeClass('disabled');
			$dealerSelectionContainer.removeClass('loading');
		}

		

		function processingMapMarkers(dealers){
			let counter = 1; 
			var color = '#007bff';		
			dealers.forEach(dealer => {
				dealers_with_label.push({label:counter, dealer:dealer});
				counter++;
			});
			dealers_with_label.forEach(d => {
				var label = d.label;
				var dealer = d.dealer;
				var marker, address, fullAddress, geocoder , pos;
				address = dealer.dealerAddress;
				fullAddress = `${address.address1}, ${address.city}, ${address.state}, ${address.postalCode}, ${address.country}`;
	
				geocoder = new google.maps.Geocoder();
				geocoder.geocode({ 'address': fullAddress }, function(results, status) {
					if (status === 'OK') {
						pos = results[0].geometry.location;
						if(label === 1){
							map.center = pos;
						}

						var labelConverted = googleMapsUtils.numberToLetter(label);
						var svgIcon = googleMapsUtils.generateIcon(labelConverted, color);

						const customMarkerIcon = {
							url: svgIcon,
							scaledSize: new google.maps.Size(32, 32), 
							anchor: new google.maps.Point(16, 32),
						};

						const markerDiv = document.createElement('div');
						markerDiv.innerHTML = svgIcon;
						markerDiv.style.position = 'relative';
						markerDiv.style.width = '48px';
						markerDiv.style	.height = '48px'; 

						marker = new google.maps.Marker({
							position: pos,
							content: markerDiv,
							icon: customMarkerIcon,
						});

						var result = utils.getXItemsFromString(dealer.dealerBrands, ',', 2);
						const brands_st = `${result.selected.join(', ')} and more`;
							
						var contentString = '';

						dust.render(DealerInfotTempl, {icon:svgIcon, dealer:dealer, address:fullAddress, brands:brands_st}, (err, output) => {
							if (err) {
								console.error(err);
							} else {
								contentString = output;
							}
						});
							
						const infoWindow = new google.maps.InfoWindow({
							content: contentString,
							headerDisabled: true,
							ariaLabel: dealer.dealerName,
						});
	
						marker.addListener('click', () => {
							closeAllInfoWindows();
							infoWindow.open({
								anchor: marker,
								map,
								shouldFocus: false
							});

							$('.dealer-row').removeClass('highlighted');

							let dataHashSelector = '[data-hash="'+dealer.dealerHash+'"]';
							$(dataHashSelector).closest('.dealer-row').addClass('highlighted');

							onMarkerClick(dataHashSelector);

							infoWindows.push(infoWindow);
						});
						marker.setMap(map);
						markers.push({position:label, distance: dealer.distance,marker:marker});

						google.maps.event.addListener(map, 'click', () => {
							closeAllInfoWindows();
						});
					} else {
						console.log('Geocode was not successful for the following reason: ' + status);
					}
				});
			});
		}

		function closeAllInfoWindows(){
			for (let i = 0; i < infoWindows.length; i++) {
				infoWindows[i].close();
			}
		}
		function onMarkerClick(dataHashSelector) {
			const $dealer = $(dataHashSelector);
			$dealer[0].scrollIntoView({ behavior: 'smooth', block: 'end' });
		}

		function handleZipSubmissionSuccess(data) {
			if (data) {
				clearError();
				zipSubmissionReady();
				$zipInput.val(data.postal_code_value);
				$selectedDealerContainer.html(data.selected_dealer_html);
				$dealersListContainer.html(data.dealer_list_html);
			}
		}

		function handleZipSubmissionError(errorMessage) {
			$formErrors.html(`${errorMessage}`);
			zipSubmissionReady();
		}


		function handleZipSubmission(e, isAutomaticSubmission) {

			let skuStripped = null;
			let catalog = null;

			if ($productPurchaseModule && $productPurchaseModule.length > 0) {
				skuStripped = $productPurchaseModule.data('sku-stripped');
				catalog = $productPurchaseModule.data('catalog');
			}

			e && e.preventDefault();

			if (rp$(this).hasClass('disabled')) {
				return;
			}

			const postalCode = $zipInput.val();
			if (e && !validatePostalCode(postalCode)) {
				return handleZipSubmissionError($modalBody.data('error-invalid-zip'));
			}

			clearError();

			// Perform Submission
			zipSubmissionWorking();
			window.rp_app.request({
				method: 'GET',
				url: '/ajax/dealers', 
				dataType: 'json',
				data: {
					postalCode,
					html: true, 
					skuStripped,
					catalog,
					skipSupplierSelectionReporting: isAutomaticSubmission
				},
				success: (data = {}) => {
					if(data.modal_map_enabled){
						initMap();
						processingMapMarkers(data.json);
					}
					handleZipSubmissionSuccess(data.html);
					processMapMarkerSideBar();
				},
				statusCode: {
					400: () => {
						zipSubmissionReady();
						handleZipSubmissionError($modalBody.data('error-400'));
					}
				},
				error: () => {
					zipSubmissionReady();
					handleZipSubmissionError($modalBody.data('error-500'));
				}
			});
		}

		function processMapMarkerSideBar(){
			var color = '#007bff';
			dealers_with_label.forEach(d => {
				var letterLabel = googleMapsUtils.numberToLetter(d.label);
				var markMap = googleMapsUtils.generateIcon(letterLabel, color);
				$('.map-marker-'+d.label).prop('src', markMap);
			});
		}

		function handleClearDealerSuccess() {
			clearError();
			$dealerSelectionModal.modal('hide');
			window.location.reload();
		}

		function handleClearDealer() {
			window.rp_app.request({
				method: 'GET',
				url: '/ajax/dealers/clear',
				dataType: 'json',
				success: (response, status) => {
					if (status === 'success') {
						handleClearDealerSuccess();
					}
				}
			});
		}

		function dealerSelectionWorking() {
			$modalBody.find('.select-dealer-button').addClass('disabled');
			$modalBody.find('.select-dealer-map-button').addClass('disabled');
		}

		function dealerSelectionReady() {
			$modalBody.find('.select-dealer-button').removeClass('disabled');
			$modalBody.find('.select-dealer-map-button').removeClass('disabled');

		}

		function handleCartFetch() {
			$modalContainer.removeClass('modal-container-with-map');
			$cartLoadingContainer.addClass('loading');

			$changeDealerWarning.addClass('hidden');
			$dealerConfirmSection.addClass('hidden');
			$dealerSelectionSection.addClass('hidden');
			$dealerConfirmationFooter.addClass('hidden');

			window.rp_app.request({
				type: 'GET',
				url: '/ajax/cart',
				dataType: 'json',
				contentType: 'application/json',
				success: handleCartHasItems,
			});
		}

		function handleCartHasItems(cart) {
			$cartContentList.empty();
			$cartLoadingContainer.removeClass('loading');

			const cartHasItems = !!get(cart, 'itemsCount');
			if (cartHasItems) {
				$changeDealerWarning.removeClass('hidden');

				$dealerConfirmSection.removeClass('hidden');
				$dealerConfirmationFooter.removeClass('hidden');
				$dealerSelectionSection.addClass('hidden');

				const $cartItems = get(cart, 'items');
				$cartItems.forEach(function CartItems_Iterator(item) {
					const $row = document.createElement('div');
					const $title = document.createElement('p');
					const $sku = document.createElement('p');

					$row.className = 'cart-content-row';
					$title.className = 'cart-item-title';
					$sku.className = 'cart-item-sku';
					$title.textContent = get(item, 'title');
					$sku.textContent = get(item, 'product_id');

					$row.append($title);
					$row.append($sku);

					$cartContentList.append($row);
				});
				$dealerConfirmSubmit.removeAttr('disabled');

			} else {
				$changeDealerWarning.addClass('hidden');

				$dealerConfirmSection.addClass('hidden');
				$dealerConfirmationFooter.addClass('hidden');
				$dealerSelectionSection.removeClass('hidden');
				const $hasDealerMap = $modalContainer.attr('has-dealer-map') === '1';
				if($hasDealerMap)
				{
					$modalContainer.addClass('modal-container-with-map');
				}
			}
		}

		function handleDealerSelectionSuccess() {
			clearError();
			dealerSelectionReady();
			$dealerSelectionModal.modal('hide');
			window.location.reload();
		}

		function handleDealerSelectionError(errorMessage) {
			$dealerSelectionError.html(`${errorMessage}`);
			dealerSelectionReady();
		}

		function handleDealerSelection(e) {
			e.preventDefault();

			if (rp$(this).hasClass('disabled')) {
				return;
			}

			const dealerHash = rp$(e.target).data('hash');
			if (!dealerHash) {
				return handleDealerSelectionError($modalBody.data('error-400'));
			}

			clearError();

			// Perform Submission
			dealerSelectionWorking();
			window.rp_app.request({
				method: 'POST',
				url: '/ajax/dealers',
				dataType: 'json',
				data: { dealerHash },
				success: handleDealerSelectionSuccess,
				statusCode: {
					400: () => {
						dealerSelectionReady();
						handleDealerSelectionError($modalBody.data('error-400'));
					}
				},
				error: () => {
					dealerSelectionReady();
					handleDealerSelectionError($modalBody.data('error-500'));
				}
			});
		}

		function handleDealerConfirmSubmit(e) {
			e.preventDefault();

			$dealerConfirmSection.addClass('hidden');
			$dealerConfirmationFooter.addClass('hidden');
			$dealerSelectionSection.removeClass('hidden');
			const $hasDealerMap = $modalContainer.attr('has-dealer-map') === '1';
			if($hasDealerMap){
				$modalContainer.addClass('modal-container-with-map');
			}
		}

		function isEnterKeyUpEvent(e) {
			return e && (e.type === 'keyup') && (e.which === 13);
		}
		
		function initMap() {
			map = new google.maps.Map(document.getElementById('map_dealers'), {
				center: { lat: 33.4318951, lng: -111.9003082 },
				zoom: 5,
			});
		}
		$onReady({
			rp$, window, document,
			label: 'dealer_selection_modal',
			fn: function () {
				// $mapsSelector = rp$("#map_dealers");
				$dealerSelectionModal = rp$('.dealer-selection-modal');
				$dealerSelectionModalTrigger = rp$('.find-dealer-button');
				$modalBody = rp$('.modal-body', $dealerSelectionModal);
				$modalContainer = rp$('.modal-container');
				$enterZipButton = rp$('.enter-zip-button', $dealerSelectionModal);
				$enterZipMapButton = rp$('.enter-zip-map-button', $dealerSelectionModal);
				$dealerSelectionError = rp$('.dealer-selection-errors', $dealerSelectionModal);
				$formErrors = rp$('.form-errors', $dealerSelectionModal);
				$selectedDealerContainer = rp$('.selected-dealer-container', $dealerSelectionModal);
				$dealerSelectionContainer = rp$('.dealer-selection-container', $dealerSelectionModal);
				$dealersListContainer = rp$('.dealers-list-container', $dealerSelectionModal);
				$zipInput = rp$('.zip-input', $dealerSelectionModal);
				$changeDealerWarning = rp$('.change-dealer-warning', $dealerSelectionModal);
				$dealerSelectionSection = rp$('.dealer-selection', $dealerSelectionModal);
				$dealerConfirmationFooter = rp$('.dealer-confirmation-footer', $dealerSelectionModal);
				$dealerConfirmSection = rp$('.dealer-confirm', $dealerSelectionModal);
				$dealerConfirmCancel = rp$('.change-dealer-cancel', $dealerSelectionModal);
				$dealerConfirmSubmit = rp$('.change-dealer-confirm', $dealerSelectionModal);
				$cartLoadingContainer = rp$('.cart-loading-container', $dealerSelectionModal);
				$cartContentList = rp$('.cart-content-list', $dealerSelectionModal);

				// To provide product data if we're in the context of a product page
				$productPurchaseModule = rp$('.product-purchase-module');

				// Automatically fetch dealers once initial geolocation complete in main.js
				rp$(document).on('geolocationComplete', handleZipSubmission.bind(this, null, true));

				// Dynamically display $changeDealerWarning
				$dealerSelectionModalTrigger.on('click', handleCartFetch);
				// Handle enter presses when $dealerSelectionModalTrigger is focused
				$dealerSelectionModalTrigger.on('keyup', function handleEnterKeyUp(e) {
					e.preventDefault();

					if (isEnterKeyUpEvent(e) && $dealerSelectionModalTrigger.click) {
						return $dealerSelectionModalTrigger.click();
					}

					return false;
				});

				// Added the modal click event to the submit button itself.
				$enterZipButton.on('click', handleZipSubmission);
				$enterZipMapButton.on('click', handleZipSubmission);
				$zipInput.on('keyup', function handleZipEnterKeyed(e) {
					e.preventDefault();

					if (isEnterKeyUpEvent(e)) {
						handleZipSubmission(e);
					}

					return false;
				});

				
				$selectedDealerContainer .on('click', '.clear-dealer-link', handleClearDealer);

				$modalBody.on('click', '.select-dealer-button', handleDealerSelection);
				$modalBody.on('click', '.select-dealer-map-button', handleDealerSelection);
				$dealerConfirmSubmit.on('click', handleDealerConfirmSubmit);

			},
		});
	})();
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
