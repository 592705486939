const utils = {};

utils.getXItemsFromString = function (data, separator, quantity) {
	const brandsArray = data.split(separator);
	const selectedBrands = brandsArray.slice(0, quantity);
	return {
		'selected':selectedBrands,
		'total':brandsArray.length,
	};
};

module.exports = utils;
